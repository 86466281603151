import { useState, useEffect } from 'react';
import './App.css';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_STOPS_QUERY } from './queries';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Autocomplete, CircularProgress, Container, TextField, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { TrainOutlined, SubwayOutlined, TramOutlined, DirectionsBus, DirectionsBoat, LocalAirport } from '@mui/icons-material/';
import debounce from 'lodash/debounce';

function App() {
  return <DepartureTable />;
}

function DepartureTable() {
  const [stop, setStop] = useState();
  const [departures, setDepartures] = useState();


  return (
    <Container maxWidth="sm" id='App'>
      <h1>Norsk Luftambulanse</h1>
      <AutoCompleteSearch setStop={setStop}/>
      {stop === null ? '' : <DepartureTableList stopPlace={stop} setDepartures={setDepartures} departures={departures} />}
    </Container>
  )
}

function AutoCompleteSearch({ setStop }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (!open) {
      return undefined;
    }


    const debounceFetch = debounce(async () => {
      setLoading(true);

      const response = await fetch(
        `https://api.entur.io/geocoder/v1/autocomplete?text=${searchValue}&layers=venue&size=10`
      );
      const data = await response.json();

      if (active) {
        const responseFiltered = data.features.map((feature) => {
          const id = feature.properties.id;
          const name = feature.properties.label;

          return {
            id,
            name,
          };
        });
        setOptions(responseFiltered);
        setLoading(false);
      }
    }, 200);

    debounceFetch();

    return () => {
      active = false;
    };
  }, [searchValue, open]);

  function handleChange (e){
    setOptions([]);
    setSearchValue(e.target.value);
  }

  return (
    <Autocomplete
      onChange={(e) => setStop(e.target.id)}
      InputLabelProps={{ shrink: true }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        // setSearchValue("")
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderOption={(props, option) => (
        <li {...props} id={option.id}>{option.name}</li>
      )}
      renderInput={(params) => (
        <TextField
          onChange={e => handleChange (e)}
          {...params}
          label="Holdeplass"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchValue !== '' && options.length === 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}


function DepartureTableList({ stopPlace }) {

  const { loading, error, data } = useQuery(GET_STOPS_QUERY, {
    variables: { id: stopPlace }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return stopPlace === undefined ? '' : <p>Error : {error.message}</p>;


  const departureList = data.stopPlace === null ? [] : data.stopPlace.estimatedCalls;
  const groupedDepartureList = groupAndSortDepartureList(departureList);

  function transportModeAndIcon(transportMode) {
    switch (transportMode) {
      case 'bus' || 'coach':
        return <div className="transportHeader"><DirectionsBus /><span>Buss</span> </div>;
        break;
      case 'metro':
        return <div className="transportHeader"><SubwayOutlined /><span>T-Bane</span></div>;
        break;
      case 'rail':
        return <div className="transportHeader"><TrainOutlined /><span>Tog</span></div>;
        break;
      case 'tram':
        return <div className="transportHeader"><TramOutlined /><span>Trikk</span></div>;
        break;
      case 'water':
        return <div className="transportHeader"><DirectionsBoat /><span>Ferge</span></div>;
        break;
      case 'air':
        return <div className="transportHeader"><LocalAirport /><span>Fly</span></div>;
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {Object.keys(groupedDepartureList).map((transportMode) => (
        <List>
          <div className="" >{transportModeAndIcon(transportMode)}</div>
          {groupedDepartureList[transportMode].map((departure) => (
            <DepartureTimeRow
              transportMode={transportMode}
              key={departure.id}
              departureTime={departure.aimedDepartureTime}
              departureNumber={departure.serviceJourney.journeyPattern.line.publicCode}
              departureName={departure.destinationDisplay.frontText}
            />
          ))}
        </List>
      ))}
    </div>
  );
}

// Define a function to group estimated departures by transportMode
function groupAndSortDepartureList(departureList) {
  const groupedDepartures = departureList.reduce((grouped, departure) => {
    let transportMode = departure.serviceJourney.journeyPattern.line.transportMode;
    
    // Check if the transport mode is "coach" and change it to "bus"
    if (transportMode === "coach") {
      transportMode = "bus";
    }

    if (!grouped[transportMode]) {
      grouped[transportMode] = [];
    }

    grouped[transportMode].push(departure);
    return grouped;
  }, {});

  // Sort estimated departures by aimedDepartureTime within each transportMode
  for (const transportMode in groupedDepartures) {
    groupedDepartures[transportMode].sort((a, b) => {
      return a.aimedDepartureTime.localeCompare(b.aimedDepartureTime);
    });
  }

  return groupedDepartures;
}

function DepartureTimeRow({ departureTime, departureNumber, transportMode, departureName }) {

  let backgroundColor = '';
  
  switch (transportMode) {
    case 'bus':
      backgroundColor = 'rgb(197, 4, 78)';
      break;
    case 'metro':
      backgroundColor = 'rgb(191, 88, 38)';
      break;
    case 'rail':
      backgroundColor = '#C34C4C';
      break;
    case 'tram':
      backgroundColor = 'rgb(100, 46, 136)';
      break;
    case 'water':
      backgroundColor = '#8ed1e3';
      break;
    case 'air':
      backgroundColor = '#fd2e5e';
      break;
    default:
      break;
  }

  // Quickfix for coach backgroundColor as it is changed in grouping function 
  if (departureNumber !== null ? departureNumber.includes("VY") : ''){
    backgroundColor = '#037D67';
  }

  return (
    <>
      <ListItemButton className="departureList">
        <ListItemIcon>
          <div style={{ backgroundColor }} className="transport">{departureNumber}</div>
        </ListItemIcon>
        <ListItemText sx={{ fontSize: "1.2rem", width: "50px", paddingLeft: "15px" }} primary={departureName} />
        <ListItemText sx={{ fontSize: "1.2rem", textAlign: "right" }} primary={moment(departureTime).format("HH:mm")} />
      </ListItemButton>
      <Divider />
    </>
  );
}

export default App;
