import { gql } from '@apollo/client';

export const GET_STOPS_QUERY = gql`
query stopPlace ($id: String!) {
    stopPlace (id: $id) {
    	id
    	name
    estimatedCalls(timeRange: 72100, numberOfDepartures: 10) {     
      realtime
      aimedArrivalTime
      aimedDepartureTime
      expectedArrivalTime
      expectedDepartureTime
      actualArrivalTime
      actualDepartureTime
      date
      forBoarding
      forAlighting
      destinationDisplay {
        frontText
      }
      quay {
        id
      }
      serviceJourney {
        journeyPattern {
          line {
            id
            name
            transportMode
            publicCode
          }
        }
      }
    }
    }
  }`;